// "use client";
import { AlcatrazLocales, alcatrazLocales } from "@/config/supported-countries";
import { ThemesG } from "@/types/design-system-types";
import { getRouteDetails } from "@/utils/helpers";
import Page from "@/utils/system/page";
import { useRouter } from "next/router";
import { useEffect, useRef } from "react";

const TrustpilotCarouselWidget = ({
  data,
  debug,
  className,
  theme,
  ...props
}: {
  data: any;
  debug: any;
  theme: ThemesG;
  className?: string;
}) => {
  const ref = useRef(null);

  useEffect(() => {
    // https://support.trustpilot.com/hc/en-us/articles/115011421468--Add-a-TrustBox-widget-to-a-single-page-application
    if ((window as any).Trustpilot) {
      (window as any).Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  const router = useRouter();
  let { locale, region, language } = getRouteDetails(router);

  if (language === "nb") {
    language = "no";
  }

  if (language === "zh") {
    language = "en";
  }

  // Annoyingly trustpilot direct link locale for GB is uk.trustpilot.com rather than gb.trustpilot.com...
  if (region === "gb") {
    region = "uk";
  }

  // We don't have any Trustpilot reviews for all Alcatraz locales yet
  const isAlcatrazLocale = alcatrazLocales.includes(locale as AlcatrazLocales);
  const availableTPLocales = ["en", "fr", "es", "pt"];
  if (isAlcatrazLocale && !availableTPLocales.includes(locale)) {
    locale = "en";
    region = "ie";
    language = "en";
  }

  return (
    <div {...props} className="relative h-full w-full">
      {debug}
      <div
        ref={ref}
        className={`trustpilot-widget h-full w-full ${className}`}
        data-locale={locale || "en-GB"}
        data-template-id="54ad5defc6454f065c28af8b"
        data-businessunit-id="58244f650000ff0005974e81"
        data-style-height={"240px"}
        data-style-width={"100%"}
        data-theme={Page.getThemeBrightness(theme) || "light"}
        data-stars="5"
        data-review-languages={language || "en"}
      >
        <a
          href={`https://${region}.trustpilot.com/review/www.cmcmarkets.com`}
          target="_blank"
          rel="noopener"
        >
          Trustpilot
        </a>
      </div>
    </div>
  );
};

export default TrustpilotCarouselWidget;
